import * as React from 'react';
import {PageBackground} from '../PageBackground';
import {PageHeader} from '../PageHeader';
import {VSpacer} from '../VSpacer';
import {Images} from '../../images/Images';
import {HAlign, HAligner} from '../HAligner';
import {Text, TextSize, TextWeight} from '../Text';
import {PageFooter} from '../PageFooter';
import {PictureFrame} from '../PictureFrame';
import {SiteButtonKind} from '../SiteButton';
import {ScheduleDemoButton} from '../ScheduleDemoButton';

// declare var Calendly:any;

export class ServicesPage extends React.Component<any, any>
{
    render()
    {
        return  <PageBackground>
                    <PageHeader/>

                    <VSpacer size={15}/>
                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'}>
                            <div className={'col-sm-12'}>
                                <VSpacer size={15}/>
                                <HAligner align={HAlign.Center}>
                                    <Text isHighlight={false} size={TextSize.h1} weight={TextWeight.w400} >
                                        IT Services from Ergalio
                                    </Text>
                                </HAligner>
                            </div>
                            <div className={'col-sm-12'} style={{marginTop:'5px'}}>
                                <HAligner align={HAlign.Center}>
                                    <Text size={TextSize.h2} spacing={0.9}>
                                        The fastest path from idea to reality
                                    </Text>
                                </HAligner>
                            </div>
                        </div>
                    </div>

                    <VSpacer size={15}/>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginBottom:'20px'}}>
                            <div className={'col-sm-12'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Software Development
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    It's no secret that there is a worldwide shortage of good software development talent. Rely on our staff to develop software for your company. Our staff is able to work with you on your time zone and with your existing software development processes. Plus, we bring years of experience to the table in how to develop software quickly and efficiently.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginBottom:'20px'}}>
                            <div className={'col-sm-12'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Software Testing & QA
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    Software development goes hand in handing with software testing and quality assurance (QA). Use our staff to help with not only software development with with software testing as well. Our services in include all types of testing from manual to automated. We work to extend your team's effetiveness with the experience and diligence of our staff.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'10px', marginBottom:'20px'}}>
                            <div className={'col-sm-6'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Constant Status Updates & Reports
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    When working with our staff, you will receive daily updates and weekly reports on our progress. We work together with your teams to provide full transparency on our work and how it measures against the allocated budget and timeline.
                                    <br/><br/>
                                    Also, we value our collaboration and always strive to improve the way we do reporting and status updates across all of our projects.
                                </Text>
                            </div>
                            <div className={'col-sm-6'}>
                                {/*<img src={Images.WorkspacePreview}/>*/}
                                <img src={Images.ITServices__Reports}/>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginBottom:'20px'}}>
                            <div className={'col-sm-12'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Outsourced Developers
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    We offer our IT service of outsourced software developers on two business models. The first model is time and materials, where we work alongside your teams for a duration of time until the project is complete. The second model is fixed bid, where we provide a set amount for the project and complete it on the timeline we provide.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginBottom:'20px'}}>
                            <div className={'col-sm-12'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Outsourced QA & Testing
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    We offer our IT service of outsourced QA on two business models as well. In the first, time and materials, our quality assurance staff tests alongside your team for a period of time until it is no longer needed. In the second, we provide a fixed price and then test the functionality that we have agreed upon, providing reports on quality defects as they come up.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{marginBottom:'40px'}}>
                        <div className={'row'} style={{padding:'0px'}}>
                            <div className={'col-sm-12'}>
                                <PictureFrame style={{border:'10px solid white', backgroundColor: '#3F4553', padding: '40px'}}>
                                    <Text color={'white'} isHighlight={false} size={TextSize.h3} weight={TextWeight.w500}>
                                        Get In Touch
                                    </Text>
                                    <VSpacer size={10}/>
                                    <Text color={'white'} isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                        Please contact us to see how we can help with your software development and testing needs.
                                    </Text>
                                    <VSpacer size={40}/>
                                    <HAligner align={HAlign.Center}>
                                        <ScheduleDemoButton kind={SiteButtonKind.Red}/>
                                        {/*<SiteButton text={'Schedule a Demo'} link={'#'}/>*/}
                                    </HAligner>
                                </PictureFrame>
                            </div>
                        </div>
                    </div>

                    <PageFooter/>
                </PageBackground>;
    }
}

export default () => <ServicesPage/>;






